export interface AppEnvironment {
  production: boolean;
  name: string;
  default: string;
  features?: {
    [name: string]: boolean;
  };
  hosts: {
    [name: string]: AppHostConfig;
  };
  values: any;
}

export interface AppHostConfig {
  host?: string;
  protocol?: string;
  port?: string;
  root?: string;
  unauthenticated?: boolean;
  local?: boolean;
  endpoints: {
    [name: string]: string;
  };
}

export const environment: AppEnvironment = {
  production: false,
  name: 'common',
  default: 'preorders',
  values: { },
  hosts: {
    preorders: {
      root: '/api/',
      endpoints: {
        'products': 'products',
        'products/types': 'products/types',
        'products/id': 'products/{id}',
        'preorders': 'preorders',
        'contacts/email': 'contacts/{email}',
        'categories/wineryId': 'categories/{wineryId}',
        'addresses': 'addresses/{contactId}',
        'link/id/options': 'link/{id}/options',
        'link/id': 'link/{id}',
        'winery/wineryId': 'winery/{wineryId}'
      }
    },
    ws: {
      root: '/api/',
      endpoints: {
        'countries': 'countries',
      }
    }
  }
};
