import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { WineryInfoService } from '../winery-info.service';
import { TranslateService } from '@ngx-translate/core';
import { locales } from '@util/locale';
import { PreorderLinkService } from '../link.service';

@Injectable({
    providedIn: 'root'
})
export class LinkResolver implements Resolve<any> {
    constructor(
        private wineryInfo: WineryInfoService,
        private translate: TranslateService,
        private preorderLinkService: PreorderLinkService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        // Always load translation settings first
        const l = locales();
        //let lan = l.stored;  // Default to 'it' if l.stored is undefined
        // Set the default language upfront
        //this.setLanguage(lan);

        // Get the linkId from route params
        const linkId = route.paramMap.get('id');
        const { wineryId, channelId } = route.queryParams || {};

        if (linkId) {
            // Fetch link info if linkId is available
            return this.preorderLinkService.get(linkId).pipe(
                concatMap(link => {
                    if (link) {
                        this.wineryInfo.loadOptions(link.id);
                        this.wineryInfo.load(link.wineryId, link.wineryChannelId);
                   if (!l.stored && link.options?.language) {
                    this.setLanguage(link.options.language);
                } else {
                    const lan = l.stored || link.options?.language || 'it';
                    this.setLanguage(lan);
                }
                    } else{
                        this.router.navigate(['/no-content']);
                        return of(null);
                    }

                    // Continue processing after setting up the translation and return the link
                    return of(link);
                }),
                catchError(err => {
                    this.router.navigate(['/no-content']);
                    return of(null);
            })
        );
        }

        // If linkId is not available, check if wineryId and channelId are provided
        if (wineryId && channelId) {
            // Load directly with winery and channel info
            this.wineryInfo.load(wineryId, channelId);
            return of(null);  // Return an empty observable to indicate no further processing
        }

        // If no valid params are found, return null
        this.router.navigate(['/no-content']);
        return of(null);
    }

    // Helper function to handle language setup
    private setLanguage(lan: string): void {
        const l = locales();
        this.translate.setDefaultLang(lan);
        this.translate.use(l.locale);

        // Handle language changes
        this.translate.onDefaultLangChange.subscribe(ev => {
            localStorage.setItem('locale', ev.lang);
            this.translate.use(ev.lang);
            if (l.stored !== ev.lang) location.reload(); // Reload if the language has changed
        });
    }
}
