import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LinkResolver } from './shared/services/resolvers/link.resolver';

const routes: Routes = [
  {
    path: 'link/:id',
    loadChildren: () => import('./products/products.module').then(m => m.ProductsPageModule),
    resolve: { linkData: LinkResolver }  
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then(m => m.ProductsPageModule),
    resolve: { linkData: LinkResolver } 
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then(m => m.CartModule)
  },
  {
    path: 'preorder',
    loadChildren: () => import('./preorder/preorder.module').then( m => m.PreorderPageModule)
  },
  {
    path: '',
    redirectTo: 'products',
    pathMatch: 'full'
  },
  {
    path: 'no-content',
    loadChildren: () => import('./no-content-page/no-content-page.module').then((m) => m.NoContentPageModule),
  },
  {
    path: '**', 
    loadChildren: () =>
      import('./not-found-page/not-found-page.module').then((m) => m.NotFoundPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
