import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { resource } from '@util/resource';
import { PreorderLinkOptions } from './link.service';

export interface WineryInfo {
  name: string;
  avatar: string;
  privacyStatementUrl: string;
}

@Injectable({
  providedIn: 'root'
})
// Os atributos wineryId e channelId estão aqui para manter o wineryId, channelId na troca de paginas
// Por favor não seja esperto
export class WineryInfoService {
  public wineryId: string;
  public channelId: string;
  public linkId: string;
  private wineryInfo$: ReplaySubject<WineryInfo> = new ReplaySubject<WineryInfo>(1);
  private wineryOptions$: ReplaySubject<PreorderLinkOptions> = new ReplaySubject<PreorderLinkOptions>(1);

  constructor() {
  }

  getWineryDetail(): Observable<WineryInfo> {
    return this.wineryInfo$;
  }

  getWineryOptions(): Observable<PreorderLinkOptions> {
    return this.wineryOptions$;
  }


  public load(wineryId: string, channelId: string) {
    this.wineryId = wineryId;
    this.channelId = channelId;

    resource('preorders://winery/wineryId')
      .params({ wineryId })
      .get<WineryInfo>()
      .subscribe(wineryInfo => this.wineryInfo$.next(wineryInfo));
  }

  public loadOptions(linkId: string) {
    this.linkId = linkId;

    resource('preorders://link/id/options')
      .params({ id: linkId })
      .get<PreorderLinkOptions>()
      .subscribe(wineryOptions => this.wineryOptions$.next(wineryOptions));
  }
}
